import Blake from "./blake.jpg";
import Jaime from "./jaime.jpg";
import Shweta from "./shweta.jpg";
import Kayla from "./Kayla.jpg"
import Shiming from "./Shiming.png";
//import Randi from "./randi.jpg";
import Koffison from "./koffison.png";
import Javaughn from "./javaughn.png";
import Don from "./Don.png";
//import Matthew from "./Matthew.jpg"
// import Kenneth from "./kenneth.jpg";
// import Majestic from "./majestic.png";
// import Don from "./don.jpeg";
// import Alex from "./alex.jpg";
// import Jennifer from "./Jennifer.png";
// import Nanami from "./Nanami.png";
// import Chelsea from "./Chelsea.jpeg";
// import Israel from "./Israel.jpeg";
// import Robert from "./Robert.jpeg";
// import Muzaffar from "./Muzaffar.jpeg";
// import Christopher from "./Christopher.jpeg";
// import Dawn from "./dawn.png";
// import Justin from "./justin.png";
// import Nima from "./nima.jpeg";
// import Amtul from "./amtul.png";
const profiles = [Blake, Jaime, Shweta, Shiming, Don, Kayla, Javaughn, Koffison];

export default profiles;
